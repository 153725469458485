main {
  min-height: 80vh;
}

.nav-link i {
  margin: 0.5rem;
}

.icon-margin i {
  margin: 0.5rem;
}

.project-title strong {
  font-size: 1.5rem;
}

.no-color-link a {
  color: inherit;
}

.bigger-icons i {
  font-size: 1.2rem;
}

.home-info h6 {
  /* margin: 0.5rem 0; */
}

.experience-margin i {
  margin: 0.2rem;
  color: #00bc8c;
}

h6 span {
  font-weight: bold;
  text-decoration: underline;
}
